import React from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ContextType as DashboardContextType, DashboardContext } from 'contexts/DashboardContext';

type Props = {
  icon: React.ReactNode;
  label: string;
};

const Option: React.FC<Props> = (props) => {
  const { sidebarOpen, updateOptionSelection } =
    React.useContext<DashboardContextType>(DashboardContext);
  const key = props.label.toLowerCase();
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const pathMappings = [
    {
      paths: ['/dashboard/lms'],
      option: 'lms',
    },
    {
      paths: ['/dashboard/wrapper/form', '/dashboard/wrapper'],
      option: 'wrapper',
    },
  ];

  const selectedOption =
    pathMappings.find((mapping) => mapping.paths.some((path) => currentPath.includes(path)))
      ?.option || 'dashboard';

  const onClick = () => {
    if (key === 'sign-out') {
      navigate('/login');
      localStorage.removeItem('live-proctor-session');
      return;
    }
    updateOptionSelection(key);
  };

  return (
    <div className="flex text-gray-600 hover:cursor-pointer w-full" onClick={onClick}>
      <Link
        to={key !== 'dashboard' ? key : '/dashboard'}
        className={classNames(
          'flex space-x-1 p-2 items-center',
          sidebarOpen ? 'rounded-lg w-full' : 'rounded-full',
          selectedOption.toLowerCase() === key ? 'bg-blue-100' : 'hover:bg-gray-100',
        )}
      >
        {props.icon}
        {sidebarOpen && <div>{props.label}</div>}
      </Link>
    </div>
  );
};

export default Option;
